<template>
  <div class="modalPopup trading_modal" v-if="isModalClose">
    <div class="modal_header">
      <h2 class="title">거래명세표</h2>
      <button type="button" class="modal_close" @click="isModalClose"></button>
    </div>
    <div class="modal_body">
      <div class="page" v-for="(n, index) in devidePage" :key="index">
        <div
          class="trading_table_wrap"
          :class="{ trading1: index2 == 0, trading2: index2 == 1 }"
          v-for="(n, index2) in 2"
          :key="n.index"
        >
          <h5>거래명세표</h5>
          <p>{{ index == 0 ? `(공급 받는자 보관용)` : `(공급자 보관용)` }}</p>
          <p class="page_num">
            page: {{ `${index + 1}/${devidePage.length}` }}
          </p>
          <table class="table1">
            <thead>
              <tr class="recipe">
                <th rowspan="5" colspan="2">
                  <div>
                    <p>
                      일 자: <span>{{ date.substr(0, 13) }}</span>
                    </p>
                    <p>
                      사업자번호:
                      <span>
                        {{ findInfoFromId(companys, getCompanyId).saup_no }}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      거래처:
                      <span>{{
                        findInfoFromId(companys, getCompanyId).name
                      }}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      주 소:<span>{{
                        findInfoFromId(companys, getCompanyId).address
                      }}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      전화번호:<span>
                        {{
                          findInfoFromId(companys, getCompanyId).phone_number
                        }}
                      </span>
                    </p>
                    <p>
                      팩스번호:<span>
                        {{ findInfoFromId(companys, getCompanyId).fax_number }}
                      </span>
                    </p>
                  </div>
                </th>
              </tr>
              <tr>
                <th>등록번호</th>
                <th colspan="4">{{ systemCompany.saup_no }}</th>
              </tr>
              <tr>
                <th>상호</th>
                <th>{{ systemCompany.name }}</th>
                <th>성명</th>
                <th>{{ systemCompany.representative }}(인)</th>
              </tr>
              <tr>
                <th>주소</th>
                <th colspan="4">
                  {{ systemCompany.address }} {{ systemCompany.detail_address }}
                </th>
              </tr>
              <tr>
                <th>업태</th>
                <th>{{ systemCompany.business_type }}</th>
                <th>종목</th>
                <th>{{ systemCompany.business_item }}</th>
              </tr>
              <tr>
                <th>합계금액</th>
                <th class="text_right">
                  {{ $makeComma(newSalesData.total_value) }}
                </th>
                <th>전화번호</th>
                <th>{{ systemCompany.phone_number }}</th>
                <th>팩스번호</th>
                <th>{{ systemCompany.fax_number }}</th>
              </tr>
            </thead>
          </table>
          <table class="table3">
            <colgroup>
              <col v-for="(n, index) in 8" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>월/일</th>
                <th>품명/규격</th>
                <th>단위</th>
                <th>수량</th>
                <th>단가</th>
                <th>공급가액</th>
                <th>세액</th>
                <th>비고</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index3) in devidePage[index]" :key="index3">
                <td>
                  {{ data != undefined ? monthDay : '' }}
                </td>
                <td>
                  <div class="text_hidden">
                    {{
                      data != undefined
                        ? findInfoFromId(products, data.product_id).name
                        : ''
                    }}
                    {{
                      data != undefined
                        ? `/ ${
                            findInfoFromId(products, data.product_id).standard
                          }`
                        : ''
                    }}
                  </div>
                </td>
                <td>
                  {{
                    data != undefined
                      ? findInfoFromId(units, data.unit_id).name
                      : ''
                  }}
                </td>
                <td class="text_right">
                  {{ data != undefined ? $makeComma(data.quantity) : '' }}
                </td>
                <td class="text_right">
                  {{ data != undefined ? $makeComma(data.cost) : '' }}
                </td>
                <td class="text_right">
                  {{ data != undefined ? $makeComma(data.supply_value) : '' }}
                </td>
                <td class="text_right">
                  {{ data != undefined ? $makeComma(data.tax) : '' }}
                </td>
                <td class="text_right">
                  {{ data != undefined ? data.detail : '' }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>합계</td>
                <td colspan="4" class="text_right">
                  {{ `${$makeComma(newSalesData.total_value)}` }}
                </td>
                <td>전잔금</td>
                <td colspan="2" class="text_right">
                  {{
                    `${$makeComma(
                      findInfoFromId(companys, newSalesData.sales.company_id)
                        .curr_balance - newSalesData.total_value,
                    )}`
                  }}
                </td>
              </tr>
              <tr>
                <td>메모</td>
                <td colspan="4"></td>
                <td>총잔금</td>
                <td colspan="2" class="text_right">
                  {{
                    `${$makeComma(
                      findInfoFromId(companys, newSalesData.sales.company_id)
                        .curr_balance,
                    )}`
                  }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <div class="modal_footer">
      <button type="button" class="btn_sub2 btn_print" @click="handlerPrint">
        <i class="ico_print"></i>출력하기
      </button>
    </div>
  </div>
</template>

<script>
import FETCH_MIXIN from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import { mapGetters } from 'vuex';

export default {
  mixins: [FETCH_MIXIN, DRAG_MODAL_MIXIN],
  props: ['newSalesData', 'getCompanyId'],
  data() {
    return {
      modalClose: true,
      date: yyyy년MM월dd일HH시mm분ss초(new Date(this.newSalesData.create_time)),
      monthDay:
        new Date(this.newSalesData.create_time).getMonth() +
        1 +
        '/' +
        new Date(this.newSalesData.create_time).getDate(),
    };
  },
  computed: {
    ...mapGetters({
      systemCompany: 'getSystemCompany',
      companys: 'getCompany',
      products: 'getProduct',
      units: 'getUnitCodes',
      boxs: 'getBoxsNotSorted',
    }),
    devidePage() {
      let clone = this.lodash.clonedeep(this.newSalesData.sales_product_list);
      let arr = [];
      clone.forEach((el, index) => {
        if (index % 12 == 0) {
          arr.push(clone.slice(index, index + 12));
        }
      });
      arr[arr.length - 1].length = 12;
      return arr.sort((a, b) => a.id - b.id);
    },
  },
  methods: {
    isModalClose() {
      this.$emit('onclose');
    },
    handlerPrint() {
      const app = document.querySelector('#app');
      const modalBody = document.querySelector('.trading_modal .modal_body');
      const printDiv = document.createElement('div');
      let modalBodyClone = modalBody.cloneNode(true);
      document.body.appendChild(printDiv);
      printDiv.className = 'trading_print_wrap';
      printDiv.append(modalBodyClone);
      app.style.display = 'none';
      window.print();
      app.style.display = 'block';
      document.body.removeChild(printDiv);
    },
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.systemCompany.id == undefined) {
      await this.FETCH_NO_SPINNER('FETCH_SYSTEM_COMPANY', '사용자 사업자');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.boxs.length < 1) {
      await this.FETCH('FETCH_BOX', '박스');
    }
  },
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    // size: A4 !important;
    margin: 0mm !important;
  }
}
</style>
