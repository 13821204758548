<template>
  <div class="article">
    <transition name="slide-fade-1">
      <div class="tbl_wrap" key="tbl">
        <div class="tbl_option">
          <div>
            <h5>매출 현황</h5>
          </div>
          <div class="tbl_opt">
            <span>출고일</span>
            <!-- <div> -->
            <div>
              <div class="input_text">
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  id="start_date"
                  :value="start_date"
                  @change="setStartDate($event)"
                  v-if="showSearchDate"
                />
              </div>
              <p>~</p>
              <div class="input_text">
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  id="end_date"
                  @change="setEndDate($event)"
                  :value="end_date"
                  v-if="showSearchDate"
                />
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
        <h6>조회수 : {{ newSales.length }}건</h6>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <colgroup class="output_col">
              <col v-for="(n, index) in 5" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>No.</th>
                <th>출고일</th>
                <th>매출처</th>
                <th>출고제품</th>
                <th>매출액</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in newSales"
                :key="item.id"
                @click="selectRow(index)"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ item.create_time | formatDateNoHours }}</td>
                <td class="text_left">
                  {{ findInfoFromId(companys, item.sales.company_id).name }}
                </td>
                <td class="text_left">
                  {{ getFirstSalesProduct(index) }}
                </td>
                <td class="text_right">
                  &#8361; {{ item.sales_value | makeComma }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div class="aside_management_mode" key="aside" v-if="selectedIndex != -1">
        <div class="head">
          <button type="button" class="btn_prev" @click="selectRow(-1)">
            <h5>매출 현황</h5>
          </button>
          <button type="button" class="btn_sub2 btn_print" @click="openModal">
            <i class="ico_print"></i>출력
          </button>
          <trading-statement
            v-if="tradingOpen"
            :newSalesData="newSalesData"
            :getCompanyId="newSalesData.sales.company_id"
            @onclose="closeModal"
          ></trading-statement>
          <div class="modal_layer" v-if="tradingOpen" @click="closeModal"></div>
        </div>
        <div class="aside_cont">
          <div class="input_text">
            <label>매출처</label>
            <div class="input_search">
              <input
                type="text"
                readonly
                :value="
                  findInfoFromId(companys, newSalesData.sales.company_id).name
                "
              />
              <!-- <button><i class="fa fa-search"></i></button> -->
            </div>
          </div>
          <div>
            <span>주문내역</span>
            <div class="mes_tbl_wrap">
              <table class="mes_tbl">
                <colgroup>
                  <col v-for="(n, index) in 12" :key="index" />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      제품명
                    </th>
                    <th>규격</th>
                    <th>과세여부</th>
                    <th>단위</th>
                    <th>주문수량</th>
                    <th>주문박스</th>
                    <th>총 출고량</th>
                    <th>단가</th>
                    <th>공급가</th>
                    <th>부가세</th>
                    <th>할인액</th>
                    <th>합계액</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(x, index) in filtered_Sales_product_list"
                    :key="x.id"
                  >
                    <td class="text_left">
                      {{ findInfoFromId(products, x.product_id).name }}
                    </td>
                    <td class="text_left">
                      {{ findInfoFromId(products, x.product_id).standard }}
                    </td>
                    <td>
                      {{
                        findInfoFromId(products, x.product_id).tax
                          ? '과세'
                          : '면세'
                      }}
                    </td>
                    <td>{{ findInfoFromId(units, x.unit_id).name }}</td>
                    <td class="text_right">{{ x.quantity | makeComma }}</td>
                    <td>
                      {{
                        x.box_unit_id == null
                          ? '낱개'
                          : findInfoFromId(boxs, x.box_unit_id).name
                      }}
                    </td>
                    <td class="text_right">
                      {{
                        findInfoFromId(products, x.product_id)
                          .product_group_yn &&
                        findInfoFromId(products, x.product_id)
                          .product_group_type_id == 2
                          ? '-'
                          : $makeComma(getTotalOutputQuantity(index))
                      }}
                    </td>
                    <td class="text_right">&#8361; {{ x.cost | makeComma }}</td>
                    <td class="text_right">
                      &#8361; {{ x.supply_value | makeComma }}
                    </td>
                    <td class="text_right">&#8361; {{ x.tax | makeComma }}</td>
                    <td class="text_right">
                      &#8361; {{ x.discount | makeComma }}
                    </td>
                    <td class="text_right">
                      &#8361; {{ (x.total_cost - x.discount) | makeComma }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <span>출고내역</span>
            <div class="mes_tbl_wrap">
              <table class="mes_tbl">
                <colgroup class="out_list_col">
                  <col v-for="(n, index) in 7" :key="index" />
                </colgroup>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>
                      제품명{{ systemCompany.expire_yn ? ` (유통기한)` : '' }}
                    </th>
                    <th>규격</th>
                    <th>Lot번호</th>
                    <th>단위</th>
                    <th>총 주문수량</th>
                    <th>출고수량</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(y, index) in concatedProductInOutList"
                    :key="y.id"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="text_left">
                      {{
                        findInfoFromId(products, y.product_control.product_id)
                          .name
                      }}
                      {{
                        systemCompany.expire_yn
                          ? ` (${SearchAndReturnExpire(y)} 까지)`
                          : ''
                      }}
                    </td>
                    <td class="text_left">
                      {{
                        findInfoFromId(products, y.product_control.product_id)
                          .standard
                      }}
                    </td>
                    <td>{{ `L${y.product_control.product_ident}` }}</td>
                    <td>
                      {{
                        findInfoFromId(units, y.product_control.unit_id).name
                      }}
                    </td>
                    <td class="text_right">
                      <b>{{
                        groupSalesByProductId.length > 0 &&
                        concatedProductInOutList.length > 0
                          ? $makeComma(
                              groupSalesByProductId.find(
                                x =>
                                  x.product_id == y.product_control.product_id,
                              ).sum,
                            )
                          : ''
                      }}</b>
                      {{
                        groupSalesByProductId.length > 0 &&
                        concatedProductInOutList.length > 0
                          ? findInfoFromId(units, y.product_control.unit_id)
                              .name
                          : ''
                      }}
                    </td>
                    <td class="text_right">
                      <b>{{ y.quantity | makeComma }}</b
                      >{{
                        findInfoFromId(units, y.product_control.unit_id).name
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <span v-show="newSalesData.material_in_out_list.length > 0"
              >포장재 소진내역</span
            >
            <div
              v-show="newSalesData.material_in_out_list.length > 0"
              class="mes_tbl_wrap"
            >
              <table class="mes_tbl">
                <colgroup>
                  <col v-for="(n, index) in 5" :key="index" />
                </colgroup>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>포장재명</th>
                    <th>규격</th>
                    <th>단위</th>
                    <th>출고수량</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in newSalesData.material_in_out_list"
                    :key="item.id"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="text_left">
                      {{ findInfoFromId(materials, item.material_id).name }}
                    </td>
                    <td class="text_left">
                      {{ findInfoFromId(materials, item.material_id).standard }}
                    </td>
                    <td>
                      {{
                        findInfoFromId(
                          units,
                          findInfoFromId(materials, item.material_id)
                            .using_unit_id,
                        ).name
                      }}
                    </td>
                    <td class="text_right">
                      <b>{{ item.quantity | makeComma }}</b
                      >{{
                        findInfoFromId(
                          units,
                          findInfoFromId(materials, item.material_id)
                            .using_unit_id,
                        ).name
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="date_options">
              <div class="receiving_info">
                <div>
                  <div class="input_text">
                    <label>합계액</label>
                    <input
                      type="text"
                      placeholder=""
                      readonly
                      :value="`₩ ${$makeComma(newSalesData.total_value)}`"
                    />
                  </div>
                  <div class="input_text">
                    <div></div>
                    <label>비과세</label>
                    <input
                      type="text"
                      placeholder=""
                      readonly
                      :value="`₩ ${$makeComma(newSalesData.tax_free_value)}`"
                    />
                  </div>
                </div>
                <div>
                  <div class="input_text">
                    <label>할인액</label>
                    <input
                      id="total_discount"
                      type="text"
                      placeholder=""
                      readonly
                      :value="`₩ ${$makeComma(newSalesData.discount)}`"
                    />
                  </div>
                  <div class="input_text">
                    <label>과세</label>
                    <input
                      type="text"
                      placeholder=""
                      readonly
                      :value="`₩ ${$makeComma(newSalesData.tax_value)}`"
                    />
                  </div>
                </div>
                <div>
                  <div class="input_text">
                    <label>매출액</label>
                    <input
                      id="sales_cost"
                      type="text"
                      placeholder=""
                      readonly
                      :value="`₩ ${$makeComma(newSalesData.sales_value)}`"
                    />
                  </div>
                  <div class="input_text">
                    <label>수금액</label>
                    <input
                      id="collect_value"
                      type="text"
                      placeholder=""
                      readonly
                      :value="`₩ ${$makeComma(totalCollectValue())}`"
                    />
                  </div>
                </div>
                <div class="delete_user" v-if="isPermissionOn">
                  <button
                    @click="checkValid"
                    v-show="selectedIndex != -1 && isPermissionOn"
                  >
                    <span></span>출고 취소
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="my_isModalOpen = false"
      @OnYesClick="
        $event => {
          my_isModalOpen = false;
          submitForm($event);
        }
      "
    ></two-button-modal>
  </div>
</template>

<script>
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import PriceMixin from '@/mixins/price';
import FavoriteMixin from '@/mixins/favorite';
import { mapGetters } from 'vuex';
import { getExpireDate, yyyymmdd, groupArrayObject } from '@/utils/func';
import TradingStatement from '@/layouts/components/TradingStatement';

export default {
  mixins: [ModalMixin, SpinnerMixin, FetchMixin, PriceMixin, FavoriteMixin],
  components: {
    TwoButtonModal,
    TradingStatement,
  },
  data() {
    return {
      showProductControlSearch: false,
      showSearchDate: false,
      tradingOpen: false,

      //delete modal
      my_modal_title: '출고 취소',
      my_modal_content:
        '출고를 취소하면 매출내역도 일괄 삭제됩니다. 정말로 취소처리 하시겠습니까?',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      newSalesSrc: 'getNewSalesFromOutput',
      newSalesData: 'getNewSalesDataFromOutput',
      selectedIndex: 'getNewSalesSelectedIndexFromOutput',
      companys: 'getCompany',
      products: 'getProduct',
      materials: 'getVisibleMaterial',
      units: 'getUnitCodes',
      boxs: 'getBoxsNotSorted',
      sales: 'getSalesFromOutput',
      start_date: 'getNewSalesStartDateFromOutput',
      end_date: 'getNewSalesEndDateFromOutput',
      date: 'getNewSalesDateFromOutput',
      sales_type: 'getSalesType',
      productControl: 'getProductControl',
      productInOutList: 'getProductStockStatusListFromProductStockStatus',
      productinOutType: 'getProductInOutType',
      systemCompany: 'getSystemCompany',
    }),
    groupSalesByProductId() {
      const productListFilter = this.lodash
        .clonedeep(this.newSalesData.sales_product_list)
        .filter(
          x =>
            !(
              this.getProductInfo(x.product_id).product_group_yn &&
              this.getProductInfo(x.product_id).product_group_type_id == 2
            ),
        );
      const arr = this.lodash.clonedeep(
        groupArrayObject(productListFilter, 'product_id'),
      );

      let list = [];
      arr.forEach(el => {
        const mapQuantity = el.element.map(
          x =>
            this.$makeNumber(x.quantity) *
            this.$makeNumber(
              x.box_unit_id != null
                ? this.findInfoFromId(this.boxs, x.box_unit_id).quantity
                : 1,
            ),
        );
        const sumValue = mapQuantity.reduce(
          (a, b) => this.$makeNumber(a) + this.$makeNumber(b),
        );
        list.push({
          product_id: el.group_key,
          sum: this.$makeNumber(sumValue),
        });
      });
      return list.sort((a, b) => a.product_id - b.product_id);
    },
    filtered_Sales_product_list() {
      if (this.newSalesData.sales_product_list.length < 1) {
        return [];
      } else {
        const clone = this.lodash
          .clonedeep(this.newSalesData.sales_product_list)
          .filter(x => x.parent_product_id == null);
        return clone;
      }
    },
    newSales() {
      if (this.newSalesSrc == undefined || this.newSalesSrc.length < 1) {
        return [];
      } else {
        const cloneList = this.lodash.clonedeep(this.newSalesSrc);
        return cloneList
          .filter(
            x =>
              !this.findInfoFromId(
                this.sales_type,
                x.sales.sales_type_id,
              ).detail.includes('return'),
          )
          .sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
      }
    },

    concatedProductInOutList() {
      const inout = this.lodash
        .clonedeep(this.newSalesData.sales_product_list)
        .map(x => x.product_in_out_list);
      return inout.reduce((a, b) => a.concat(b));
    },
  },
  methods: {
    getFirstSalesProduct(index) {
      if (this.newSales[index].sales_product_list[0] != undefined) {
        const productCount = this.lodash
          .clonedeep(this.newSales[index].sales_product_list)
          .filter(x => x.parent_product_id == null);
        const firstProduct = this.getProductInfo(productCount[0].product_id)
          .name;
        return productCount.length > 1
          ? firstProduct + ' 외 ' + Number(productCount.length - 1) + '개 제품'
          : firstProduct;
      } else {
        return '';
      }
    },
    getProductInfo(id) {
      let hit = this.products.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    SearchAndReturnExpire(salesControl) {
      const foundControlSrc = this.lodash
        .clonedeep(this.productControl)
        .find(x => x.id == salesControl.product_control.id);
      console.log('foundControlSrc', foundControlSrc);
      if (foundControlSrc == undefined) {
        return 'NaN';
      } else {
        const product = this.findInfoFromId(
          this.products,
          foundControlSrc.product_id,
        );
        return yyyymmdd(
          new Date(
            getExpireDate(
              new Date(foundControlSrc.create_time),
              product.expire_year,
              product.expire_month,
              product.expire_day,
            ),
          ),
        );
        // return this.getExpireDate(foundControlSrc);
      }
    },
    // getExpireDate(item) {
    //   console.log('in getExpireDate');
    //   const products = this.findInfoFromId(this.products, item.product_id);
    //   console.log('foundProduct', products);

    // },
    openModal() {
      this.tradingOpen = true;
    },
    closeModal() {
      this.tradingOpen = false;
    },
    checkValid() {
      const in_out_list_filtered_by_return = this.lodash
        .clonedeep(this.productInOutList)
        .filter(x =>
          this.findInfoFromId(
            this.productinOutType,
            x.product_in_out_type_id,
          ).detail.includes('return'),
        );

      const maped_by_return_sales_product_id = in_out_list_filtered_by_return.map(
        x => x.return_sales_product_id,
      );

      const maped_sales_product_id = this.newSalesData.sales_product_list.map(
        x => x.id,
      );

      const filtered_returned_sales_products = maped_sales_product_id.filter(
        x => maped_by_return_sales_product_id.includes(x),
      );

      console.log(
        'filtered_returned_sales_products',
        filtered_returned_sales_products,
      );

      if (filtered_returned_sales_products.length < 1) {
        this.my_isModalOpen = true;
      } else {
        this.openOneButtonModal(
          '취소 불가',
          '반품 내역이 있는 제품이 포함되어 있습니다.',
        );
        return;
      }
    },
    submitForm() {
      const payload = this.lodash.clonedeep(this.newSalesData);
      console.log(payload);

      this.showSpinner();
      this.$store
        .dispatch('DELETE_SALES_ACCOUNT_ALL', payload)
        .then(async () => {
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_SALES_TO_OUTPUT',
            {
              start_date: this.start_date,
              end_date: this.end_date,
              check_only_not_completed: false,
            },
            '수주',
          );
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '매출 내역',
          );
          this.selectRow(-1);
        })
        .catch(() => {
          this.openOneButtonModal(
            '출고 취소 실패',
            '출고내역을 취소하는 중 오류가 발생하였습니다.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    getTotalOutputQuantity(index) {
      // const newSalesData = this.newSalesData;
      // const sales_product =this.newSalesData.sales_product_list[index];
      const sales_product = this.filtered_Sales_product_list[index];
      const product_in_out_list = sales_product.product_in_out_list;
      if (product_in_out_list == undefined || product_in_out_list.length < 1) {
        return 0;
      } else {
        const mapQuantity = product_in_out_list.map(x => x.quantity);
        const reducer = (accumulator, currentValue) =>
          accumulator + currentValue;
        return mapQuantity.reduce(reducer);
      }
    },
    selectRow(index) {
      this.$store.commit('setNewSalesSelectedIndexToOutput', index);
      if (index != -1) {
        this.$store.commit(
          'setNewSalesDataToOutput',
          this.lodash.clonedeep(this.newSales[index]),
        );
      } else {
        this.$store.commit('setNewSalesDataToOutput', null);
      }
    },
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '출고일 조회기간 오류',
            `출고일 조회기간 최후날짜(${this.end_date})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setNewSalesStartDateToOutput', e.target.value);
          this.selectRow(-1);
          this.FETCH_WITH_PAYLOAD(
            'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '매출 내역',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setNewSalesStartDateToOutput',
          yyyymmdd(new Date()),
        );
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '출고일 조회기간 오류',
            `출고일 조회기간 최초날짜(${this.start_date})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setNewSalesEndDateToOutput', e.target.value);
          this.selectRow(-1);
          this.FETCH_WITH_PAYLOAD(
            'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '매출 내역',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setNewSalesEndDateToOutput', yyyymmdd(new Date()));
      }
    },
    async FETCH_SALES_TO_OUTPUT() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_SALES_TO_OUTPUT', {
          start: this.start_date,
          end: this.end_date,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '발주 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    totalCollectValue() {
      return this.lodash
        .clonedeep(this.newSalesData.sales_product_list)
        .map(x => x.collect_value)
        .reduce((a, b) => this.$decimalAdd(a, b));
    },
  },
  async created() {
    await this.FETCH('FETCH_PRODUCT_STATUS', '입출고 정보');
    await this.FETCH('FETCH_PRODUCT_CONTROL', '제품 객체');

    if (this.productinOutType.length < 1) {
      await this.FETCH('FETCH_PRODUCT_IN_OUT_TYPE', '제품 입출고 유형');
    }
    if (this.sales_type.length < 1) {
      await this.FETCH('FETCH_SALES_TYPE', '매출유형');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.boxs.length < 1) {
      await this.FETCH('FETCH_BOX', '박스');
    }
    if (this.start_date == null) {
      var date = new Date();
      await this.$store.commit('setNewSalesEndDateToOutput', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      await this.$store.commit('setNewSalesStartDateToOutput', yyyymmdd(date2));
    }
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_SALES_TO_OUTPUT',
      {
        start_date: this.start_date,
        end_date: this.end_date,
        check_only_not_completed: false,
      },
      '수주',
    );
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
      {
        start: this.start_date,
        end: this.end_date,
      },
      '매출 내역',
    );
    this.showSearchDate = true;
  },
};
</script>

<style>
/* 애니메이션 진입 및 진출은 다른 지속 시간 및  */
/* 타이밍 기능을 사용할 수 있습니다. */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.55s ease-in-out;
}
.slide-fade-enter {
  transform: translateX(100%);
}
.slide-fade-leave-to {
  transform: translateX(100%);
}
</style>
